import React from 'react';
import BreadcrumbSchema from '../../components/BreadcrumbSchema';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FaChartLine } from 'react-icons/fa';
import ServiceTemplate from '../../components/service/ServiceTemplate';

const WebNew = ({ data }) => {
  const seoData = {
    title: 'Webコンサルティング｜奈良のホームページ制作なら｜インヴォルブ',
    description:
      'Webマーケティング戦略・SEO・広告運用・コンバージョン改善まで、あらゆる視点からWebサイトの成果を最大化。データ分析に基づいた的確な施策で、ビジネスの成長をサポートします。',
    pageUrl: 'https://www.involve-in.jp/service/consulting',
    ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
  };

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: 'サービス一覧', url: 'https://www.involve-in.jp/service/' },
    { name: 'Webコンサルティング', url: seoData.pageUrl },
  ];

  const works = data.allContentfulCaseStudy.nodes; // Contentful から施工事例を取得

  return (
    <>
      {/* ✅ 構造化パンくずデータを渡す */}
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <ServiceTemplate
        title="Webコンサルティング"
        subtitle="サイトの成果を最大化"
        icon={FaChartLine}
        description="Webマーケティング戦略・SEO・広告運用・コンバージョン改善まで、あらゆる視点からWebサイトの成果を最大化。データ分析に基づいた的確な施策で、ビジネスの成長をサポートします。"
        seo={{
          title:
            'Webコンサルティング｜奈良のホームページ制作なら｜インヴォルブ',
          description:
            'Webマーケティング戦略・SEO・広告運用・コンバージョン改善まで、あらゆる視点からWebサイトの成果を最大化。データ分析に基づいた的確な施策で、ビジネスの成長をサポートします。',
          pageUrl: 'https://www.involve-in.jp/service/consulting',
          ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
        }}
        breadcrumbs={[
          { name: 'ホーム', url: 'https://www.involve-in.jp/' },
          {
            name: 'サービス一覧',
            url: 'https://www.involve-in.jp/service/',
          },
          {
            name: 'Webコンサルティング',
            url: 'https://www.involve-in.jp/service/consulting',
          },
        ]}
        benefits={[
          {
            title: 'Web戦略の策定',
            description:
              '目標達成に向けた具体的なWeb施策を立案し、成果につながる戦略を構築。',
          },
          {
            title: '広告運用最適化',
            description:
              'Google広告・SNS広告の運用を最適化し、費用対効果（ROI）を最大化。',
          },
          {
            title: 'データ分析・改善',
            description:
              'Googleアナリティクスを活用し、ユーザー行動を分析しながらPDCAを回し、継続的に改善を実施。',
          },
        ]}
        flow={[
          {
            title: '現状分析',
            duration: '1週間',
            description:
              'サイトの流入データ、コンバージョン率（CVR）、課題を徹底分析し、改善ポイントを特定。',
          },
          {
            title: '戦略設計',
            duration: '1週間',
            description:
              'SEO・広告・SNSを組み合わせた最適な戦略を策定し、成果につながるマーケティングプランを構築。',
          },
          {
            title: '施策実行',
            duration: '2週間',
            description:
              'Google広告・SNS広告の運用、コンテンツSEOの強化、UI/UX改善など、具体的な施策を実施。',
          },
          {
            title: '分析・改善',
            duration: '継続',
            description:
              'データを分析しながら施策の効果を検証し、PDCAを回しながら最適化を継続。',
          },
        ]}
        preparation={[
          {
            title: '目標の設定',
            description:
              'Webサイトで達成したい目標（売上UP・集客増・問い合わせ獲得など）を明確にし、戦略の方向性を決定。',
          },
          {
            title: '競合サイトの調査',
            description:
              '競合のWeb施策を分析し、自社の強みを活かした差別化戦略を構築。',
          },
          {
            title: 'Webマーケティングの基礎理解',
            description:
              'SEOや広告運用の基本的な仕組みを理解しておくと、施策の進行がスムーズに。',
          },
          {
            title: '現状データの整理',
            description:
              'アクセス解析データや広告運用状況を事前に整理し、精度の高い分析と改善策の立案を可能に。',
          },
        ]}
        works={works.map((work) => ({
          title: work.title,
          image:
            work.image && work.image.length > 0
              ? getImage(work.image[0].gatsbyImageData)
              : null, // 🔥 最初の画像のみ取得
        }))}
        price="¥300,000〜"
        cta="まずは無料相談"
      />
      {/* ✅ 表示用のパンくずは ServiceTemplate で管理 */}
    </>
  );
};

// GraphQL クエリ（Contentful の施工事例を取得）
export const query = graphql`
  query {
    allContentfulCaseStudy(sort: { publishDate: DESC }) {
      nodes {
        title
        slug
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;

export default WebNew;
